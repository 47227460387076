import React from 'react';
import 'antd/dist/antd.css';
import { Layout, Menu, Breadcrumb } from 'antd';
import PageLayout from '../components/Layout';
import ContactUsForm from '../components/ContactUsForm';
import { Helmet } from "react-helmet";
import Meta from '../components/Meta';

const { Content } = Layout;

export default (props) => {
  
  return (
  <PageLayout selectedKey="contact-us">
    <Meta location={props.location} />
    <ContactUsForm />
  </PageLayout>
)};