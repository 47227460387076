import React, { useState } from 'react';
import 'antd/dist/antd.css';
import cover2 from '../../images/digital-marketing-cover.jpeg';
import { Form, Input, Button, Typography, Col, Row, Card, Result } from 'antd';
const axios = require('axios').default;

export default () => {

    const [displayForm, setDisplayForm] = useState(true);
    
    const onFinish = (values: any) => {
        // sendMail(values);
        // axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";
        axios.post("https://wisecode-backend.herokuapp.com/contact/", JSON.stringify({...values, phone: ""}))
        .then(function (response) {
            console.log(response);
          });
        setDisplayForm(false);
        console.log('Success:', values);
      };
    
      const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
      };
    
  
  return (
    <Row justify="space-between" className="" style={{marginTop: "5em", paddingTop: "6em", backgroundBlendMode: "overlay", background: `url(${cover2})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundColor: "#00000050"}}>
        <Col sm={7} xs={24} style={{width: "100%"}} className="px-5 d-flex flex-column justify-content-end">
            <Typography.Title className="text-light pb-1" level={4} style={{ margin: 0}}>
                <u>Office address</u>
            </Typography.Title>
            <p className="text-light">
                #1055, 4/9th Cross, E and F block, Ramakrishna nagar, Mysore - 570023
                <br />
                <b>Phone number</b>: +91 7353557978
                <br />
                <b>Contact Email</b>: contact@wisecodeinc.com
            </p>
        </Col>
        <Col sm={12} xs={24} order={1} style={{paddingBottom: "5em"}} className="order-1 order-md-1 d-flex flex-column justify-content-center">
            <div className="px-5" style={{textAlign: "center"}} >
                <Card title="Contact Us" style={{alignItems: "center"}} >
                    {displayForm ? (
                        <Form
                            className="py-3"
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            >
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Please input your Name' }]}
                            >
                                <Input />
                            </Form.Item>
        
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{ required: true, message: 'Please input your Email', type: "email" }]}
                            >
                                <Input />
                            </Form.Item>
        
                            <Form.Item
                                label="Query"
                                name="query"
                                rules={[{ required: true, message: 'Please input your Query' }]}
                            >
                                <Input.TextArea />
                            </Form.Item>
        
                            <Form.Item wrapperCol={{ offset: 2, span: 3 }} className="px-3">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    ) : (
                        <Result
                            status="success"
                            title="Query Successfully submitted"
                            extra={[
                            <Button type="primary" key="console" onClick={() => setDisplayForm(true)}>
                                Submit Another Query
                            </Button>
                            ]}
                        />
                    )}
                
                </Card>
            </div>
        </Col>
    </Row>
)};